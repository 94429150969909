@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

body {
  --color-text: #201f1e;
  --color-text-light: #8c8b8a;
  --color-main: #0c93a0;
  --color-link-hover: #08656E;
  --color-background: #f6f6f6;

  &.dark {
    --color-text: #dbd7d2;
    --color-background: #222;
    --color-link-hover: #58E1ED;
  }
}

html {
  font-size: 18px;
  font-family: "Lato", sans-serif;

  color: var(--color-text);
}

body {
  padding: 3rem 1rem;

  max-width: 1140px;
  margin: 0 auto;
  background: var(--color-background);
  color: var(--color-text);

  &::before {
    content: "";
    display: block;
    height: 40px;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: var(--color-main);
  }
}

h1, h2, h3, h4 {
  text-transform: uppercase;
  letter-spacing: 3px;
  color: var(--color-text);
}

h2, h3, h4 {
  font-weight: 400;
}

button {
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 18px;
}

a, button {
  color: var(--color-main);
  transition: color .5s ease-in-out;

  &:hover {
    color: var(--color-link-hover);
  }
}

ul {
  list-style-type: none;
  list-style-position: inside;
  padding-left: 0;

  li::before {
    content: "\2022";
    color: var(--color-text-light);
    display: inline-block;
    width: 1em;
  }
}

figure {
  margin: 0;
}

.switch_theme {
  position: relative;
  width: 45px;
  height: 45px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    position: absolute;
  }

  .moon {
    opacity: 0;
  }

  .dark & {
    .moon {
      opacity: 1;
    }

    .sun {
      opacity: 0;
    }
  }
}

nav.lang {
  z-index: 1;
  list-style-type: none;

  ul {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: end;
  }

  li::before {
    content: none;
  }

  a {
    text-decoration: none;

    &.current {
      font-weight: 700;
    }
  }
}

header {
  h1, h2 {
    text-align: center;
  }

  align-items: center;
  padding: 2rem 0;

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 4px;
    line-height: 1.1;
    margin: 1rem 0 .5rem;
    color: var(--color-text);
  }

  h2 {
    font-weight: 300;
    text-transform: uppercase;
    color: var(--color-text-light);
    margin: .8rem 0;
  }
}

.contact {
  width: max-content;
  margin-left: auto;
  margin-right: auto;

  & > * {
    margin: 0 0 .4rem;
    font-size: 20px;
  }

  a {
    color: var(--color-text);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: var(--color-main);
    }
  }

  .icon-before {
    position: relative;

    svg {
      width: 20px;
      height: 20px;
      stroke: var(--color-main);
      margin-right: .5rem;
    }
  }
}

main {
  h2 {
    text-align: center;
  }

  section {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid var(--color-text);
  }
}

.job {
  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  h3 {
    margin-bottom: .5rem;
  }
}

ul.job-meta {
  margin: 0;
  color: var(--color-text-light);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  li {

    &::before {
      content: none;
    }

    &:not(:last-child)::after {
      content: "•";
      margin: 0 .5rem;
    }
  }
}

.education {
  margin-bottom: 1.5rem;

  h3 {
    margin-bottom: .2rem;
    font-weight: 700;
    font-size: 16px;
  }

  p {
    margin: 0;
  }

  .date {
    font-size: 16px;
    color: var(--color-text-light);
  }
}

footer {
  margin-top: 5rem;
  text-align: center;
}

@media screen and (min-width: 1025px) {
  header, main {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 0;

    & > *:nth-child(1) > * {
      margin-right: 2rem;
    }

    & > *:nth-child(2) > * {
      margin-left: 2rem;
    }
  }

  header {
    h1, h2 {
      text-align: left;
    }
  }

  section {
    & > h2 {
      position: relative;
      width: max-content;

      &::after {
        transition: width .5s ease;
        content: "";
        display: block;
        background: var(--color-main);
        height: 2px;
        width: 0;
        position: absolute;
        bottom: -.3rem;
        left: 0;
      }
    }

    &:hover, &:focus {
      & > h2::after {
        width: 105%;
      }
    }
  }

  main {
    border-top: 1px solid var(--color-text);

    section {
      border-bottom: none;
      padding: 2rem 0;
      margin-bottom: 0;
    }

    div > section:not(:last-child) {
      border-bottom: 1px solid var(--color-text);
    }

    & > div:nth-child(2) {
      border-left: 1px solid var(--color-text);
    }
  }
}